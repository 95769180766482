import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "144",
  height: "144",
  "fill-rule": "evenodd",
  "clip-rule": "evenodd",
  viewBox: "0 0 48 48"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#fff\" d=\"m4.868 43.303 2.694-9.835a18.94 18.94 0 0 1-2.535-9.489C5.032 13.514 13.548 5 24.014 5a18.87 18.87 0 0 1 13.43 5.566A18.87 18.87 0 0 1 43 23.994c-.004 10.465-8.522 18.98-18.986 18.98h-.008a18.97 18.97 0 0 1-9.073-2.311z\"></path><path fill=\"#fff\" d=\"M4.868 43.803a.5.5 0 0 1-.482-.631l2.639-9.636a19.5 19.5 0 0 1-2.497-9.556C4.532 13.238 13.273 4.5 24.014 4.5a19.37 19.37 0 0 1 13.784 5.713A19.36 19.36 0 0 1 43.5 23.994c-.004 10.741-8.746 19.48-19.486 19.48a19.54 19.54 0 0 1-9.144-2.277l-9.875 2.589a.5.5 0 0 1-.127.017\"></path><path fill=\"#cfd8dc\" d=\"M24.014 5a18.87 18.87 0 0 1 13.43 5.566A18.87 18.87 0 0 1 43 23.994c-.004 10.465-8.522 18.98-18.986 18.98h-.008a18.97 18.97 0 0 1-9.073-2.311l-10.065 2.64 2.694-9.835a18.94 18.94 0 0 1-2.535-9.489C5.032 13.514 13.548 5 24.014 5m0-1C12.998 4 4.032 12.962 4.027 23.979a20 20 0 0 0 2.461 9.622L3.903 43.04a.998.998 0 0 0 1.219 1.231l9.687-2.54a20 20 0 0 0 9.197 2.244c11.024 0 19.99-8.963 19.995-19.98A19.86 19.86 0 0 0 38.153 9.86 19.87 19.87 0 0 0 24.014 4\"></path><path fill=\"#40c351\" d=\"M35.176 12.832a15.67 15.67 0 0 0-11.157-4.626c-8.704 0-15.783 7.076-15.787 15.774a15.74 15.74 0 0 0 2.413 8.396l.376.597-1.595 5.821 5.973-1.566.577.342a15.75 15.75 0 0 0 8.032 2.199h.006c8.698 0 15.777-7.077 15.78-15.776a15.68 15.68 0 0 0-4.618-11.161\"></path><path fill=\"#fff\" d=\"M19.268 16.045c-.355-.79-.729-.806-1.068-.82-.277-.012-.593-.011-.909-.011s-.83.119-1.265.594-1.661 1.622-1.661 3.956 1.7 4.59 1.937 4.906 3.282 5.259 8.104 7.161c4.007 1.58 4.823 1.266 5.693 1.187s2.807-1.147 3.202-2.255.395-2.057.277-2.255c-.119-.198-.435-.316-.909-.554s-2.807-1.385-3.242-1.543-.751-.237-1.068.238c-.316.474-1.225 1.543-1.502 1.859s-.554.357-1.028.119-2.002-.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285-.277-.474-.03-.731.208-.968.213-.213.474-.554.712-.831.237-.277.316-.475.474-.791s.079-.594-.04-.831c-.117-.238-1.039-2.584-1.461-3.522\"></path>", 5)
  ])))
}
export default { render: render }